<template>
  <b-card>
    <h2>Blogs</h2>
    <div class="mt-1 mb-2 d-flex align-items-center justify-content-between">
      <div>
        <b-button variant="primary" @click="$router.push('/blogs/create')">Add new</b-button>
      </div>
      <div class="col-md-2 pr-0">
        <label>Type</label>
        <v-select v-model="type" :options="['OUR', 'INDUSTRY']"/>
      </div>
    </div>
    <div>
      <b-table :fields="fields" :items="blogs">
        <template #cell(actions)="data">
          <b-button size="sm" @click="goToDetails(data.item)">Edit</b-button>
        </template>
      </b-table>
    </div>
    <div class="mt-3 d-flex justify-content-center">
      <b-pagination
          v-model="pagination.page"
          :total-rows="pagination.totalCount"
          :per-page="10"
          @row-clicked="goToDetails"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </div>
  </b-card>
</template>

<script>
import httpBlogs from '@/http/blogs'
import {BCard, BTable, BPagination, BButton} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BTable,
    vSelect,
    BPagination,
    BButton
  },
  data() {
    return {
      fields: [
        {key: 'title', sortable: false},
        {key: 'url', sortable: false},
        {key: 'storyType', label: 'Type'},
        {key: 'actions', label: ''}
      ],
      blogs: [],
      pagination: {
        totalCount: 20,
        page: 1
      },
      type: null
    }
  },
  watch: {
    type() {
      this.pagination.page = 1
      this.fetchBlogs()
    },
    'pagination.page'(page) {
      this.fetchBlogs()
    }
  },
  methods: {
    fetchBlogs() {
      httpBlogs.getBlogs(this.type, this.pagination.page).then(response => {
        this.blogs = response.results
        this.pagination.totalCount = response.totalCount
      })
    },
    goToDetails(item) {
      this.$router.push('/blogs/' + item.url)
    }
  },
  created() {
    this.fetchBlogs()
  }
}
</script>
